import type { SetImaIntegrationConfigurationPayload } from '@orus.eu/backend/src/routers/configuration'
import { Button, ContentContainerBackoffice, spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import type { Language } from '@orus.eu/translations'
import { trpc } from '../../../../client'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { validateInteger, validateRequiredField, type ValidationResult } from '../../../../lib/validation'
import { BackofficeConfigurationEditor } from '../../../templates/backoffice-configuration-editor'

/**
 * IMA is the "assisteur". This admin page allows to edit the configuration of the integration.
 * @constructor
 */
export default function BackofficeAdminImaPage(): JSX.Element {
  const navigateToBackofficeAdmin = useNavigateTo({ to: '/bak/admin' })

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header
        title="IMA Integration Configuration"
        leftButton={
          <Button icon="arrow-left-regular" size="small" variant="secondary" onClick={navigateToBackofficeAdmin} />
        }
      />
      <BackofficeConfigurationEditor<SetImaIntegrationConfigurationPayload>
        enableCheckboxLabel="Enable IMA Integration"
        fields={fields}
        loadConfiguration={loadConfiguration}
        saveConfiguration={saveConfiguration}
        validators={validators}
        blankConfigurationFactory={newConfiguration}
        fieldNames={fieldsNames}
      />
    </ContentContainerBackoffice>
  )
}

const fields = ['domainName', 'port', 'userName', 'password', 'intermediateCertificate', 'rootCertificate'] as const

const fieldsNames: {
  [key in keyof SetImaIntegrationConfigurationPayload]: string
} = {
  domainName: 'FTP host',
  password: 'FTP password',
  userName: 'FTP user',
  port: 'FTP port',
  intermediateCertificate: 'CA Intermediate certificat',
  rootCertificate: 'CA Root certificat',
}

const validators: {
  [key in keyof SetImaIntegrationConfigurationPayload]: (
    value: string,
    language: Language,
  ) => ValidationResult<SetImaIntegrationConfigurationPayload[key]>
} = {
  domainName: validateRequiredField,
  password: validateRequiredField,
  userName: validateRequiredField,
  port: validateInteger,
  intermediateCertificate: validateRequiredField,
  rootCertificate: validateRequiredField,
}

function newConfiguration(): SetImaIntegrationConfigurationPayload {
  return {
    domainName: '',
    port: 0,
    userName: '',
    password: '',
    intermediateCertificate: '',
    rootCertificate: '',
  }
}

async function loadConfiguration(): Promise<SetImaIntegrationConfigurationPayload | null> {
  return (await trpc.configuration.getImaIntegrationConfiguration.query()) ?? null
}
async function saveConfiguration(value: SetImaIntegrationConfigurationPayload | null): Promise<void> {
  await trpc.configuration.setImaIntegrationConfiguration.mutate(value ?? undefined)
}
