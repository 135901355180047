import { Box, Button, Typography } from '@mui/material'
import { CheckboxContainer, spacing, useAsyncCallback } from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { createElement, useState, type FormEvent } from 'react'
import { useApi } from '../../lib/use-api/use-api'
import { type ValidationResult } from '../../lib/validation'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { ValidatedTextField } from '../molecules/validated-text-field'
import { WithLabel } from '../organisms/with-label'
import { NoPasswordManager } from './no-password-manager'

export type ConfigurationComponentProps<Configuration> = {
  configuration: Configuration | null
}

export type ConfigurationComponent<Configuration> = (props: ConfigurationComponentProps<Configuration>) => JSX.Element

/**
 * @see BackofficeConfigurationEditor
 * @param Configuration the type of the configuration object you're editing
 */
export type BackofficeConfigurationEditorProps<Configuration> = {
  enableCheckboxLabel: string
  /**
   * A callback that the editor will use to load the initial value
   */
  loadConfiguration: () => Promise<Configuration | null>
  /**
   * A callback that the editor will use when it needs to save the configuration. Use this to persist the received value
   * @param configuration
   */
  saveConfiguration: (configuration: Configuration | null) => Promise<void>
  /**
   * A callback that the editor will use when it needs to instantiate the configuration with default values
   */
  blankConfigurationFactory: () => Configuration
  /**
   * The list of the names of the fields that should appear in the form, by order of appearance
   */
  fields: readonly (keyof Configuration)[]
  /**
   * A human-readable label for each field
   */
  fieldNames: { [key in keyof Configuration]: string }
  /**
   * A validator for each field
   */
  validators: {
    [key in keyof Configuration]: (value: string, language: Language) => ValidationResult<Configuration[key]>
  }
  /**
   * An arbitrary component of your choice that's inserted between the checkbox and the fields.
   * Takes a configuration object as it's only props
   */
  contentBeforeFields?: ConfigurationComponent<Configuration>
  /**
   * An arbitrary component of your choice that's inserted after the checkbox.
   * Takes a configuration object as it's only props
   */
  contentAfterFields?: ConfigurationComponent<Configuration>
}

/**
 * This component provides a generic vay to edit key/value configuration objects with an interface using :
 *  - a checkbox to set the whole object to null when it's unchecked
 *  - a validated text fields for each key.
 * See BackofficeAdminImaPage for examples of how to use it.
 * @param props
 * @constructor
 */
export function BackofficeConfigurationEditor<Configuration>(
  props: BackofficeConfigurationEditorProps<Configuration>,
): JSX.Element {
  const configurationData = useApi(props.loadConfiguration)

  return configurationData.ready ? (
    <LoadedConfigurationEditor
      initialConfiguration={configurationData.data}
      blankConfigurationFactory={props.blankConfigurationFactory}
      saveConfiguration={props.saveConfiguration}
      enableCheckboxLabel={props.enableCheckboxLabel}
      fields={props.fields}
      fieldNames={props.fieldNames}
      validators={props.validators}
      contentBeforeFields={props.contentBeforeFields}
      contentAfterFields={props.contentAfterFields}
    />
  ) : (
    <GlobalLoadingState />
  )
}

type LoadedConfigurationEditorProps<Configuration> = {
  initialConfiguration: Configuration | null
  saveConfiguration: (configuration: Configuration | null) => Promise<void>
  blankConfigurationFactory: () => Configuration
  enableCheckboxLabel: string
  fields: readonly (keyof Configuration)[]
  fieldNames: { [key in keyof Configuration]: string }
  validators: {
    [key in keyof Configuration]: (value: string, language: Language) => ValidationResult<Configuration[key]>
  }
  contentBeforeFields?: ConfigurationComponent<Configuration>
  contentAfterFields?: ConfigurationComponent<Configuration>
}

function LoadedConfigurationEditor<Configuration>(props: LoadedConfigurationEditorProps<Configuration>): JSX.Element {
  const { saveConfiguration } = props

  const [currentConfiguration, setCurrentConfiguration] = useState<Configuration | null>(props.initialConfiguration)
  const [state, setState] = useState<'editing' | 'submitting'>('editing')

  const enabled = currentConfiguration !== null

  const setEnabled = (newValue: boolean) => {
    if (newValue) {
      setCurrentConfiguration(props.blankConfigurationFactory())
    } else {
      setCurrentConfiguration(null)
    }
  }

  const handleSubmit = useAsyncCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setState('submitting')

      try {
        await saveConfiguration(currentConfiguration)
        document.location.reload()
      } catch (_err) {
        alert('Failed to update configuration')
        setState('editing')
      }
    },
    [currentConfiguration, saveConfiguration],
  )

  const reset = () => {
    setCurrentConfiguration(props.initialConfiguration === null ? null : { ...props.initialConfiguration })
  }

  const hasChanges = currentConfiguration !== props.initialConfiguration

  if (state === 'submitting') {
    return <p>Applying changes...</p>
  }

  return (
    <NoPasswordManager>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            marginTop: spacing[60],
            display: 'flex',
            flexDirection: 'column',
            gap: spacing[60],
          }}
        >
          <CheckboxContainer checked={enabled} onChange={setEnabled}>
            <Typography variant="body1">{props.enableCheckboxLabel}</Typography>
          </CheckboxContainer>

          {props.contentBeforeFields ? (
            createElement(props.contentBeforeFields, { configuration: currentConfiguration })
          ) : (
            <></>
          )}

          {props.fields.map((key) => (
            <ConfigurationFieldEditor
              key={String(key)}
              label={props.fieldNames[key]}
              enabled={enabled}
              initialValue={props.initialConfiguration ? `${props.initialConfiguration[key]}` : ''}
              validator={props.validators[key]}
              handleChange={(value) => {
                if (currentConfiguration) {
                  setCurrentConfiguration({
                    ...currentConfiguration,
                    [key]: value,
                  })
                }
              }}
            />
          ))}

          {props.contentAfterFields ? (
            createElement(props.contentAfterFields, { configuration: currentConfiguration })
          ) : (
            <></>
          )}

          <Box sx={{ paddingBottom: spacing[70] }}>
            <Button variant="outlined" disabled={!hasChanges} onClick={reset}>
              Reset changes
            </Button>
            <Button sx={{ marginLeft: spacing[50] }} variant="contained" disabled={!hasChanges} type="submit">
              Apply changes
            </Button>
          </Box>
        </Box>
      </form>
    </NoPasswordManager>
  )
}

type ConfigurationFieldEditorProps<Configuration, Key extends keyof Configuration> = {
  label: string
  enabled: boolean
  initialValue: string
  validator: (value: string, language: Language) => ValidationResult<Configuration[Key]>
  handleChange: (value: Configuration[Key] | null) => void
}

function ConfigurationFieldEditor<Configuration, Key extends keyof Configuration>(
  props: ConfigurationFieldEditorProps<Configuration, Key>,
): JSX.Element {
  return (
    <WithLabel label={props.label}>
      <ValidatedTextField
        disabled={!props.enabled}
        initialValue={props.initialValue}
        validator={props.validator}
        onChange={props.handleChange}
        multiline
      />
    </WithLabel>
  )
}
